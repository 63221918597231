import { UserRole } from '../../pages/users/types'
export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  role: UserRole[]
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
      role: [1, 2],
    },
    {
      name: 'courses',
      displayName: 'menu.courses',
      meta: {
        icon: 'folder_shared',
      },
      role: [1, 2],
    },
    {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'group',
      },
      role: [2],
    },
    {
      name: 'faq',
      displayName: 'menu.faq',
      meta: {
        icon: 'quiz',
      },
      role: [1, 2],
    },
    {
      name: 'settings',
      displayName: 'menu.settings',
      meta: {
        icon: 'settings',
      },
      role: [1, 2],
    },
  ] as INavigationRoute[],
}
